import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WaikikiImage from "../../content/assets/waikiki.jpg"

class Index extends React.Component {
  state = {
    videoPlaying: false
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const branches = data.branches.edges
    const design = branches.find(n => n.node.name === `design`).node
    const studio = branches.find(n => n.node.name === `studio`).node
    const press = branches.find(n => n.node.name === `press`).node
    const trade = branches.find(n => n.node.name === `trade`).node
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Tropically Flavored Design & Development" />
        <article className="index">
          <section className={ "hero " + (this.state.videoPlaying ? "playing" : "")}>
            <h1>
              <span>Tropically Flavored</span> <span><span className="design">Design</span> <span className="and">&</span> <span className="development">Development</span></span>
            </h1>
            <div className="video">
              <video
                autoPlay loop muted playsInline
                preload="auto"
                src="waikiki.mp4"
                alt="Palm trees frame an ocean view on the beach in Waikiki."
                poster={WaikikiImage}
                onPlay={() => {
                  this.setState({ videoPlaying: true })
                }}
              />
            </div>
          </section>
          <section
            className="introduction"
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html
            }}
          />
          <section className="branches">
            <hr />
            <section className="design">
              <h2><span>Kuokoa</span> Design</h2>
              <section dangerouslySetInnerHTML={{
                __html: design.childMarkdownRemark.html
              }} />
            </section>
            <Img
              fluid={data.desktopImage.childImageSharp.fluid}
              className="desktop"
              alt="Desktop with a computer, keyboard, mouse, notebook with sketches and a fresh cup of coffee."
            />
            <hr />
            <section className="studio">
              <h2><span>Kuokoa</span> Studio</h2>
              <section dangerouslySetInnerHTML={{
                __html: studio.childMarkdownRemark.html
              }} />
            </section>
            <hr />
            <section className="trade">
              <h2><span>Kuokoa</span> Trade</h2>
              <section dangerouslySetInnerHTML={{
                __html: trade.childMarkdownRemark.html
              }} />
            </section>
            <Img
              fluid={data.tableImage.childImageSharp.fluid}
              className="table"
              alt="End table with the book, Food Vibes, and a small tabletop fern."
            />
            <hr />
            <section className="press">
              <h2><span>Kuokoa</span> Press</h2>
              <section dangerouslySetInnerHTML={{
                __html: press.childMarkdownRemark.html
              }} />
            </section>
          </section>
        </article>
        <article  />

      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/" } }) {
      html
      frontmatter {
        title
        description
      }
    }
    branches: allFile(filter: {
      sourceInstanceName: {eq: "branches"},
      extension: {eq: "md"}
    }) {
      edges {
        node {
          childMarkdownRemark {
            html
          }
          name
        }
      }
    }
    beachImage: file(relativePath: {eq: "waikiki.jpg"}) {
      childImageSharp {
        fluid {
          src
          srcSet
          sizes
          base64
          aspectRatio
        }
      }
    }
    desktopImage: file(relativePath: {eq: "desktop.jpg"}) {
      childImageSharp {
        fluid {
          src
          srcSet
          sizes
          base64
          aspectRatio
        }
      }
    }
    tableImage: file(relativePath: {eq: "table.jpg"}) {
      childImageSharp {
        fluid {
          src
          srcSet
          sizes
          base64
          aspectRatio
        }
      }
    }
  }
`
